.background--black {
  background-color: #000000;
}
.background--yellow {
  background-color: #f7fed0;
}
.background--yellowDark {
  background-color: #a2971d;
}
.background--red {
  background-color: rgba(255, 4, 4, 0.6);
}
.background--lightRed {
  background-color: rgba(255, 4, 4, 0.1);
}
.background--green {
  background-color: #00c72c;
}
.background--lightGreen {
  background-color: rgba(0, 199, 44, 0.1);
}
.background--primary {
  background-color: #00378B;
}
.background--primary75 {
  background-color: rgba(0, 55, 139, 0.75);
}
.background--primary50 {
  background-color: rgba(0, 55, 139, 0.5);
}
.background--primary15 {
  background-color: rgba(0, 55, 139, 0.15);
}
.background--primary5 {
  background-color: rgba(0, 55, 139, 0.05);
}
.background--white {
  background-color: white;
}
.background--white75 {
  background-color: rgba(255, 255, 255, 0.75);
}
.background--white50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.background--white15 {
  background-color: rgba(255, 255, 255, 0.15);
}
.background--white5 {
  background-color: rgba(255, 255, 255, 0.05);
}
.background--textBlack {
  background-color: rgba(0, 55, 139, 0.75);
}
.background--whiteBg {
  background-color: white;
}
.background--sidebarBg {
  background-color: white;
}
.background--appBg {
  background-color: #b3b3b3;
}
.color--black {
  color: #000000;
}
.color--yellow {
  color: #f7fed0;
}
.color--yellowDark {
  color: #a2971d;
}
.color--red {
  color: rgba(255, 4, 4, 0.6);
}
.color--lightRed {
  color: rgba(255, 4, 4, 0.1);
}
.color--green {
  color: #00c72c;
}
.color--lightGreen {
  color: rgba(0, 199, 44, 0.1);
}
.color--primary {
  color: #00378B;
}
.color--primary75 {
  color: rgba(0, 55, 139, 0.75);
}
.color--primary50 {
  color: rgba(0, 55, 139, 0.5);
}
.color--primary15 {
  color: rgba(0, 55, 139, 0.15);
}
.color--primary5 {
  color: rgba(0, 55, 139, 0.05);
}
.color--white {
  color: white;
}
.color--white75 {
  color: rgba(255, 255, 255, 0.75);
}
.color--white50 {
  color: rgba(255, 255, 255, 0.5);
}
.color--white15 {
  color: rgba(255, 255, 255, 0.15);
}
.color--white5 {
  color: rgba(255, 255, 255, 0.05);
}
.color--textBlack {
  color: rgba(0, 55, 139, 0.75);
}
.color--whiteBg {
  color: white;
}
.color--sidebarBg {
  color: white;
}
.color--appBg {
  color: #b3b3b3;
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
.repeaterField {
  background-color: rgba(0, 55, 139, 0.05);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 55, 139, 0.15);
}
.repeaterField__bodyRow {
  border-bottom: 1px solid rgba(0, 55, 139, 0.15);
}
.repeaterField__bodyRow {
  display: flex;
}
.repeaterField__bodyRowFields {
  flex-grow: 1;
  padding: 0.5em;
}
.repeaterField__bodyRowBtns {
  width: 1.5em;
  padding-top: 0.5em;
  display: flex;
  justify-content: flex-start;
}
.repeaterField__footer {
  padding: 0.5em;
}
.repeaterField__rowBtn {
  transition: all 0.2s ease;
  opacity: 0.5;
}
.repeaterField__rowBtn:hover {
  opacity: 0.85;
}
.KnEditForm__pre {
  display: none;
}
[showBorders1] .KnEditForm__debug {
  display: block;
}
[showBorders5] .KnEditForm__pre {
  display: block;
}
.KnEditForm__stepTabs {
  position: sticky;
  top: -1px;
  z-index: 10;
}
.KnEditForm__stepTabs::after {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  content: "";
  height: 10px;
  background: linear-gradient(#000000, rgba(0, 0, 0, 0));
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
  transition: all 0.15s ease;
}
.KnEditForm__stepTabs--isSticky::after {
  opacity: 0.15;
}
.KnEditForm__debug {
  display: none;
}
.KnEditForm .chronologySubForm__row {
  display: flex;
  outline: 0px solid red;
  align-items: center;
}
.KnEditForm .chronologySubForm__row:not(:first-child) {
  padding-top: 0.5em;
}
.KnEditForm .chronologySubForm__hr {
  width: 100%;
}
.KnEditForm .chronologySubForm__or {
  margin: 0 2%;
}
.KnEditForm .chronologySubForm__text--from {
  width: 27%;
  margin-right: 2%;
}
.KnEditForm .chronologySubForm__text--to {
  width: 27%;
  margin-right: 2%;
}
.KnEditForm .chronologySubForm__cSelect--from {
  width: 20%;
  margin-right: 2%;
}
.KnEditForm .chronologySubForm__cSelect--to {
  width: 20%;
}
.KnEditForm .chronologySubForm__approximatedCheckbox {
  margin-right: 1em;
}
.KnEditForm .FormField {
  --color: currentColor;
  --colorOpacity: 1;
  --colorOpacity-hover: 1;
  --colorOpacity-focus: 1;
  --colorOpacity-disabled: 0.5;
  --bgColor: currentColor;
  --bgOpacity: 0.1;
  --bgOpacity-hover: 0.2;
  --bgOpacity-focus: 0.2;
  --bgOpacity-disabled: 0.05;
}
.KnEditForm__labelCell {
  padding-top: 0.4em;
}
.KnEditForm__labelInline {
  padding-top: 0.4em;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  padding-left: 1em;
}
.KnEditForm__radioGroupInner {
  display: inline-flex;
  flex-grow: 1;
}
.KnEditForm__radioGroupLabel {
  display: inline-flex;
  align-items: center;
  padding: 0 2em 0 0.5em;
}
.KnEditForm__searchFieldWrapper {
  position: relative;
}
.KnEditForm__searchField {
  --padding: 2em;
}
.KnEditForm__search .MhIcon--type-search {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  margin-right: 0.5em;
  opacity: 0.35;
}
.KnEditForm__search .MhIcon--type-rotate-cw {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  margin-right: 0.5em;
  pointer-events: none;
  opacity: 0.75;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.KnEditForm__search .MhIcon--type-calendar,
.KnEditForm__search .MhIcon--type-map-pin {
  margin-right: 0.5em;
  flex-shrink: 0;
  opacity: 0.75;
}
.KnEditForm__searchResults {
  transition: all 0.5s ease;
  margin-top: 0.5em;
  overflow: hidden;
  overflow-y: auto !important;
  background-color: rgba(0, 55, 139, 0.05);
  border-color: rgba(0, 55, 139, 0.15);
  border-style: solid;
}
.KnEditForm__searchCountResults {
  position: absolute;
  top: 0.45em;
  right: 2em;
  opacity: 0.75;
  pointer-events: none;
}
.KnEditForm__searchNoResultsMsg {
  transition: all 0.1s ease;
  height: 2em;
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.KnEditForm__searchNoResultsMsgIcon {
  opacity: 0.75 !important;
  margin-right: 0.5em;
}
.KnEditForm__searchNoResultsMsgLabel {
  opacity: 0.75;
}
.KnEditForm__searchResult {
  transition: all 0.1s ease;
  height: 2em;
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: pointer;
}
.KnEditForm__searchResultLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.KnEditForm__searchResult + .KnEditForm__searchResult {
  border-top: 1px solid rgba(0, 55, 139, 0.15);
}
.KnEditForm__searchResult--isInactive:hover {
  background-color: rgba(0, 55, 139, 0.15);
}
.KnEditForm__searchResult--isActive {
  background-color: rgba(0, 55, 139, 0.75);
  color: white;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.vue-treeselect__label {
  overflow: visible;
}
.KnEditForm__message {
  position: relative;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  padding: 0.5em;
}
.KnEditForm__message::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 0.15em;
  background-color: currentColor;
  opacity: 0.25;
}
.KnEditForm__message::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 0.15em;
  border: 1px solid;
  opacity: 0.5;
}
.KnEditForm__messageIcon {
  margin-right: 0.5em;
  flex-shrink: 0;
}
